import React from 'react';

import { styled, ThemeProvider } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar';
import { createTheme, Grid, List, Toolbar, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { usePlatformConfigContext } from 'contexts/PlatformConfigContext';
import gradientWithComma from 'utils/gradientWithComma';
import { useForceComponentUpdateOnLanguageChange } from 'i18n';
import { useAuthorisedSections } from './sections';
import WithPermissions from '../Permissions';
import ResizedImage from '../ResizedImage';
import AppbarMenuItem from './AppbarMenuItem';
import CongregatedMenuItems from './CongregatedMenuItems';
import useGetAppbarLinksCount from './useGetAppbarLinksCount';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const StyledAppbar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: open ? drawerWidth : -drawerWidth, // Initial marginLeft value
  width: `calc(100% - ${drawerWidth}px)`, // Initial width value
}));

const theme = createTheme({
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            background: '#263e67',
          },
        },
      },
    },
  },
});

const Appbar = () => {
  const appBarLinksCount = useGetAppbarLinksCount();

  const platformConfig = usePlatformConfigContext();
  const authorisedSections = useAuthorisedSections();
  useForceComponentUpdateOnLanguageChange();

  return (
    <ThemeProvider theme={theme}>
      <StyledAppbar
        position="fixed"
        sx={{
          background: gradientWithComma(platformConfig.navbar_color),
          width: '100%',
          textAlign: 'left',
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            background: gradientWithComma(platformConfig.navbar_color),
            width: '100%',
            justifyContent: 'center', // Set justify content to center when items are visible
            position: 'initial',
            top: 'auto',
            left: 'auto',
            zIndex: 'auto',
          }}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                marginLeft: '0.9rem',
              }}
            >
              <NavLink to="/">
                <ResizedImage
                  src={platformConfig.logo_main}
                  alt={`${platformConfig.title} logo`}
                  height={45}
                  maxWidth={80}
                />
              </NavLink>
            </Grid>
            <Grid
              item
              sx={{ display: 'flex', alignItems: 'center', marginLeft: 1 }}
            >
              <List
                component="nav"
                aria-label="platform sections"
                sx={{
                  flexGrow: 1,
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                {authorisedSections
                  .slice(0, appBarLinksCount)
                  .map((section) => (
                    <AppbarMenuItem section={section} key={section.id} />
                  ))}
                <CongregatedMenuItems
                  sections={authorisedSections.slice(appBarLinksCount)}
                />
              </List>
            </Grid>
            <WithPermissions superuser>
              <Grid
                item
                xs
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography
                  sx={{
                    marginRight: '1rem',
                  }}
                >
                  {platformConfig?.backend_version}
                </Typography>
              </Grid>
            </WithPermissions>
          </Grid>
        </Toolbar>
      </StyledAppbar>
    </ThemeProvider>
  );
};

export default Appbar;
