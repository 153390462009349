import { useMemo } from 'react';

import { useMediaQuery } from '@mui/system';
import { useTheme } from '@mui/material';

import sections from './sections';

const useGetAppbarLinksCount = () => {
  const screenWidth = window.innerWidth;
  // Temporary hok to get the count of links to be displayed in the appbar
  // TODO : Display as many links as possible based on the screen size

  const theme = useTheme();
  const isSmallerThanXl = useMediaQuery(theme.breakpoints.down('xl'));

  const displayNavLinksCount = useMemo(() => {
    if (screenWidth <= 1000) return 4;
    if (screenWidth <= 1130) return 5;
    if (screenWidth <= 1320) return 6;
    if (screenWidth <= 1460) return 7;
    if (screenWidth <= 1560) return 8;
    if (isSmallerThanXl) return sections().length - 1;

    return sections().length;
  }, [screenWidth, isSmallerThanXl]);

  return displayNavLinksCount;
};

export default useGetAppbarLinksCount;
