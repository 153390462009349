import useHasPermission from 'components/common/Permissions/useHasPermission';
import CommonPermissions from 'permissions/CommonPermissions';
import { useUsersCurrentRetrieveQuery } from 'services/api/general';

interface IProps {
  userId: number;
}

const useUpdateUserPermission = ({ userId }: IProps) => {
  const { data: curUser } = useUsersCurrentRetrieveQuery();
  const checkUserPermission = useHasPermission();

  const canUpdateUsers = checkUserPermission({
    permission: CommonPermissions.UPDATE_USERS,
  });

  let canUpdateOwnUser = checkUserPermission({
    permission: CommonPermissions.UPDATE_OWN_USER_DETAILS,
  });

  if (canUpdateOwnUser) {
    canUpdateOwnUser = userId === curUser?.id;
  }

  return { canUpdateUsers, canUpdateOwnUser };
};

export default useUpdateUserPermission;
