import React, { ComponentProps } from 'react';

import { Stack, Typography } from '@mui/material';
import { ErrorMessage as FormErrorMessage } from '@hookform/error-message';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

interface ErrorMessageProps extends ComponentProps<typeof FormErrorMessage> {
  name: string;
  errors: Record<string, any>;
  // warning should be undefined when it isn't supposed to show
  warnings?: Array<string | undefined>;
}

const ErrorMessage = ({
  errors,
  name,
  warnings,
  ...props
}: ErrorMessageProps) => {
  return (
    <Stack spacing={0.5} mt={0.5}>
      {/* WARNINGS */}
      {warnings && (
        <Stack
          alignItems="center"
          direction="row"
          spacing={0.5}
          sx={{ color: 'darkorange' }}
        >
          {warnings.some((warning) => warning && warning.length > 0) && (
            <ReportProblemIcon sx={{ fontSize: '16px' }} />
          )}
          <Stack spacing={1}>
            {warnings.map(
              (message) =>
                message && (
                  <Typography component="p" variant="subtitle2" key={message}>
                    {message}
                  </Typography>
                ),
            )}
          </Stack>
        </Stack>
      )}

      {/* ERRORS */}
      {errors && Array.isArray(errors[name]) ? (
        <FormErrorMessage
          name={name}
          errors={errors}
          as="p"
          render={() => (
            <Stack
              alignItems="center"
              direction="row"
              spacing={0.5}
              sx={{ color: 'red' }}
            >
              <ReportProblemIcon sx={{ fontSize: '16px' }} />
              <Stack spacing={1}>
                {errors[name].map(({ message }: { message: string }) => {
                  return (
                    <Typography component="p" variant="subtitle2" key={message}>
                      {message}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          )}
        />
      ) : (
        <FormErrorMessage
          render={({ message }) => (
            <Stack
              alignItems="center"
              mt={0.5}
              direction="row"
              spacing={0.5}
              sx={{ color: 'red' }}
            >
              <Stack spacing={1}>
                {message &&
                  message.split('<br>').map((msg) => (
                    <Stack
                      direction="row"
                      key={msg}
                      spacing={1}
                      alignItems="center"
                    >
                      <ReportProblemIcon sx={{ fontSize: '16px' }} />
                      <Typography component="p" variant="subtitle2">
                        {msg}
                      </Typography>
                    </Stack>
                  ))}
              </Stack>
            </Stack>
          )}
          as="p"
          name={name}
          errors={errors}
          {...props}
        />
      )}
    </Stack>
  );
};
export default ErrorMessage;
