import React from 'react';

import { Box, MenuItem, Select } from '@mui/material';
import { User } from 'ts_shared';
import { useTranslation } from 'react-i18next';

import { FormFieldDescription } from 'components/common/Forms';
import { useResellersListQuery } from 'services/api/general';
import { ResourceFormProps } from 'types';
import ControllerWithError from 'libs/react-hook-form/ControllerWithError';
import useIsInternalUsersPage from 'hooks/useIsInternalUsersPage';

const UserResellerDropdown = ({ control }: ResourceFormProps<User>) => {
  const { t } = useTranslation('common');
  const isInternalUsersPage = useIsInternalUsersPage();

  const { data: resellers } = useResellersListQuery(undefined, {
    skip: !isInternalUsersPage,
  });

  if (!isInternalUsersPage) return null;

  return (
    <Box sx={{ display: 'grid' }}>
      <FormFieldDescription
        title={t('settings.userResellers', 'User Resellers')}
        subtitle={t(
          'settings.resellersUserBelongsTo',
          'Resellers that the user belongs to',
        )}
        required
      />
      <ControllerWithError
        control={control}
        name="resellers"
        render={({ field: { onChange, value } }) => {
          return (
            <Select
              id="user-reseller-dropdown"
              size="small"
              multiple
              fullWidth
              onChange={(e) => {
                onChange(e.target.value);
              }}
              value={value || []}
              variant="standard"
            >
              {resellers?.map((reseller) => (
                <MenuItem key={reseller.id} value={reseller.id}>
                  {reseller.name}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
    </Box>
  );
};

export default UserResellerDropdown;
