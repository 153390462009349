import React, { PropsWithChildren, useState } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { enUS, zhCN, ja } from 'date-fns/locale';
import i18n from 'i18n';

const CustomLocalizationProvider = ({ children }: PropsWithChildren) => {
  const [locale, setLocale] = useState(enUS);
  i18n.on('languageChanged', (lng) => {
    if (lng === 'zh') setLocale(zhCN);
    else if (lng === 'ja') setLocale(ja);
    else setLocale(enUS);
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  );
};

export default CustomLocalizationProvider;
