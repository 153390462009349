import { useMemo } from 'react';

import { createTheme } from '@mui/material';
import {
  enUS as dataGridEnUS,
  zhCN as dataGridZhCN,
  jaJP as dataGridJaJP,
} from '@mui/x-data-grid/locales';
import {
  enUS as coreEnUS,
  zhCN as coreZhCN,
  jaJP as coreJaJP,
} from '@mui/material/locale';
import {
  enUS as dateEnUS,
  zhCN as dateZhCN,
  jaJP as dateJaJP,
} from '@mui/x-date-pickers-pro/locales';

import { usePlatformConfigContext } from 'contexts/PlatformConfigContext';
import snakeToCamel from 'utils/handleConvertSnakeCaseToCamelCase';
import i18n, { useForceComponentUpdateOnLanguageChange } from 'i18n';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    subheading1: true;
    tag: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
    xxxl: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    tagButton: true;
  }
}

const PARTNER_THEME = {
  primary: {
    main: '#2c862f',
    light: '#505392',
    dark: '#000039',
  },
  secondary: {
    main: '#ff9a03',
    light: '#ffcb49',
    dark: '#c66b00',
    contrastText: '#ffffff',
  },
  error: {
    main: '#f44336',
  },
  text: {
    primary: '#000000',
  },
  background: {
    default: '#fafafa',
  },
};

const useMainTheme = () => {
  const platformConfig = usePlatformConfigContext();
  useForceComponentUpdateOnLanguageChange();
  const lng = i18n.language;

  const mainTheme = useMemo(() => {
    const camelThemePalette = snakeToCamel(platformConfig.theme_palette || {});

    let localization;
    if (lng === 'zh') {
      localization = [dateZhCN, dataGridZhCN, coreZhCN];
    } else if (lng === 'ja') {
      localization = [dateJaJP, dataGridJaJP, coreJaJP];
    } else {
      localization = [dateEnUS, dataGridEnUS, coreEnUS];
    }

    return createTheme(
      {
        palette: camelThemePalette || PARTNER_THEME,
        components: {
          MuiButtonBase: {
            defaultProps: {
              disableRipple: true,
            },
          },
          MuiButton: {
            variants: [
              {
                props: { variant: 'tagButton' },
                style: {
                  display: 'block',
                  width: '100%',
                  borderBottom: '1px solid #80808061',
                  textTransform: 'capitalize',
                  textAlign: 'left',
                  fontSize: '.7rem',
                  padding: '.4rem',
                },
              },
            ],
            defaultProps: {
              disableElevation: true,
              disableRipple: true,
              size: 'small',
            },
          },
          MuiTypography: {
            variants: [
              {
                props: {
                  variant: 'subheading1',
                },
                style: {
                  fontWeight: 'bolder',
                  fontSize: 'smaller',
                },
              },
              {
                props: { variant: 'tag' },
                style: {
                  padding: '.4rem .6rem',
                  borderBottom: '1px solid #80808061',
                  display: 'block',
                  textAlign: 'center',
                },
              },
            ],
          },
          MuiCard: {
            styleOverrides: {
              root: {
                borderRadius: 2,
              },
            },
          },
          MuiTooltip: {
            defaultProps: {
              arrow: true,
              disableInteractive: true,
              placement: 'top',
              slotProps: {
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -5],
                      },
                    },
                  ],
                },
              },
            },
          },
          MuiDrawer: {
            defaultProps: {
              ModalProps: {
                sx: {
                  zIndex: 1250,
                },
              },
              onClick: (e) => {
                e.stopPropagation();
              },
            },
          },
        },
        typography: {
          fontFamily: 'sans-serif',
          fontWeightLight: 300,
          fontWeightRegular: 400,
        },
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 2000,
            xxxl: 2500,
          },
        },
      },
      ...localization,
    );
  }, [platformConfig.theme_palette, lng]);

  return mainTheme;
};

export default useMainTheme;
