import moment from 'moment';
import usersApi from 'services/api/general/usersApi';
import store from 'store';

export const LONG_DATE_FORMAT = {
  'DD/MM/YYYY': 'DD MMMM, YYYY',
  'MM/DD/YYYY': 'MMMM DD, YYYY',
  'YYYY/MM/DD': 'YYYY, MMMM DD',
};

export const getLongDateRangeFormat = () => {
  const state = store.getState() as any;
  const { data } = usersApi.endpoints.usersCurrentRetrieve.select()(state);

  return LONG_DATE_FORMAT[data?.preferred_date_format || 'DD/MM/YYYY'];
};

const getFormattedDate = (date: Date, format?: string) => {
  const state = store.getState() as any;
  const { data } = usersApi.endpoints.usersCurrentRetrieve.select()(state);

  return moment(date).format(
    format || `hh:mm A, ${data?.preferred_date_format || 'DD/MM/YYYY'}`,
  );
};

export const getEntireDay = (dateOrString: Date | string) => {
  let date: Date;
  if (typeof dateOrString === 'string') date = new Date(dateOrString);
  else date = dateOrString;

  return new Date(date.getTime() + 86399000);
};

export default getFormattedDate;
