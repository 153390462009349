import React from 'react';

import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { useTranslation } from 'react-i18next';

import { useConfirm } from 'contexts/ConfirmationDialogProvider';
import { useOtpProvisionNewMutation } from 'services/api/general';

const qrCodeSize = 250;

const ProvisionOTP = () => {
  const { t } = useTranslation('common');
  const [getProvisionUri, { data }] = useOtpProvisionNewMutation();
  const confirm = useConfirm();

  const handleProvision = () => {
    confirm(
      {
        description: t(
          'common.confirmProvisionNewOtpForAccount',
          'This will provision a new OTP for your account. Are you sure?',
        ),
      },
      getProvisionUri,
    );
  };

  return (
    <Stack spacing={1} sx={{ alignItems: 'center' }}>
      {data ? (
        <QRCodeSVG
          value={data}
          height={qrCodeSize}
          width={qrCodeSize}
          size={qrCodeSize}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          width={qrCodeSize}
          height={qrCodeSize}
          animation={false}
        />
      )}
      {data && (
        <Typography variant="body2">
          {t(
            'common.scanQrCodeWithAuthApp',
            'Scan the QR code with your authenticator app',
          )}
        </Typography>
      )}
      <Button onClick={handleProvision} variant="contained">
        {t('common.provisionNewOtp', 'Provision New OTP')}
      </Button>
    </Stack>
  );
};

export default ProvisionOTP;
