import React, { useState, createContext, useMemo, useContext } from 'react';

import { v4 as uuidv4 } from 'uuid';

import ConfirmationDialog from 'components/common/ConfirmationDialog';
import { ConfirmationDialogProps } from 'components/common/ConfirmationDialog/types';

type Dialog = ConfirmationDialogProps & { id: string };

type ConfirmationDialogValues = {
  dialogs: Dialog[] | undefined;
  setDialogs: React.Dispatch<React.SetStateAction<Dialog[] | undefined>>;
};

const ConfirmationDialogCtx = createContext({} as ConfirmationDialogValues);

const ConfirmationDialogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [dialogs, setDialogs] = useState<Dialog[]>();

  const memoizedValues = useMemo(
    () => ({ dialogs, setDialogs }),
    [dialogs, setDialogs],
  );

  const handleRemoveDialog = (dialogId: string) => {
    setDialogs((pre) => pre?.filter((dialog) => dialog.id !== dialogId));
  };

  return (
    <ConfirmationDialogCtx.Provider value={memoizedValues}>
      {dialogs?.map((dialog) => (
        <ConfirmationDialog
          afterClose={() => handleRemoveDialog(dialog.id)}
          key={dialog.id}
          triggerOnMount
          {...dialog}
        />
      ))}
      {children}
    </ConfirmationDialogCtx.Provider>
  );
};

export const useConfirm = () => {
  const { setDialogs } = useContext(ConfirmationDialogCtx);

  /**
   * @param {Omit<Dialog, 'id' | 'onConfirm'>} newDialog - dialog props
   * @param {() => void} onResolve - callback to call when user clicks confirm
   * @param {() => void} onCancel - callback to call when user clicks cancel
   */
  const confirm = (
    newDialog: Omit<Dialog, 'id' | 'onConfirm'>,
    onResolve: () => void,
    onCancel?: () => void,
  ) => {
    new Promise<void>((resolve, reject) => {
      setDialogs((pre) => [
        ...(pre || []),
        {
          ...newDialog,
          id: uuidv4(),
          onConfirm: resolve,
          onCancel: reject,
        },
      ]);
    })
      .then(onResolve)
      .catch(() => {
        if (onCancel) onCancel();
      });
  };

  return confirm;
};

export default ConfirmationDialogProvider;
