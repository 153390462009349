import React, { PropsWithoutRef } from 'react';

import type { User } from 'ts_shared';

import type { ResourceFormProps } from 'types';
import { useCompaniesListQuery } from 'services/api/general';
import { useTranslation } from 'react-i18next';
import { SelectFieldRenderer } from '../../../../common/Forms/FieldRenderers';

const UserCompanyDropdown = ({
  control,
  setValue,
}: PropsWithoutRef<ResourceFormProps<User>>) => {
  const { data: companies } = useCompaniesListQuery();
  const { t } = useTranslation('common');
  const menuItems = companies?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));
  return (
    <SelectFieldRenderer
      required
      control={control}
      name="company"
      subtitle={t(
        'settings.companyUserBelongsTo',
        'Company to which the user belongs',
      )}
      label={t('settings.userCompany', 'User Company')}
      items={menuItems || []}
      selectProps={{
        onChange: (e) => {
          // On change of company, reset sites
          if (setValue) {
            setValue('sites', []);
            setValue('company', e.target.value as string);
          }
        },
      }}
    />
  );
};

export default UserCompanyDropdown;
