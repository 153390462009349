import React, { PropsWithoutRef } from 'react';

import { Box } from '@mui/material';
import { AccountState, User, Languages } from 'ts_shared';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

import { useUsersCurrentRetrieveQuery } from 'services/api/general';
import type { FormFieldsType, ResourceFormProps } from 'types';
import {
  S3ImageField,
  SelectFieldRenderer,
  TextFieldRenderer,
} from 'components/common/Forms/FieldRenderers';
import PasswordFieldRenderer from 'components/common/Forms/FieldRenderers/PasswordFieldRenderer';

import { DATE_FORMATS } from 'consts';

const FIELDS: FormFieldsType<User>[] = [
  {
    title: i18n.t('settings.firstName', 'First Name', { ns: 'common' }),
    caption: i18n.t(
      'settings.firstNameDescription',
      'The first name of the user',
      { ns: 'common' },
    ),
    fieldName: 'first_name',
    type: 'text',
    required: true,
  },
  {
    title: i18n.t('settings.lastName', 'Last Name', { ns: 'common' }),
    caption: i18n.t(
      'settings.lastNameDescription',
      'The last name of the user',
      { ns: 'common' },
    ),
    fieldName: 'last_name',
    type: 'text',
  },
  {
    title: i18n.t('settings.email', 'E-Mail', { ns: 'common' }),
    caption: i18n.t(
      'settings.emailDescription',
      'The e-mail address of the user',
      { ns: 'common' },
    ),
    fieldName: 'email',
    type: 'text',
    required: true,
  },
  {
    title: i18n.t('settings.username', 'Username', { ns: 'common' }),
    caption: i18n.t(
      'settings.usernameDescription',
      'The username the user will log in with',
      { ns: 'common' },
    ),
    fieldName: 'username',
    type: 'text',
    required: true,
  },
];

interface LanguageChoice {
  label: string;
  value: Languages;
}
const LANGUAGE_CHOICES: LanguageChoice[] = [
  { label: 'English', value: 'en' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Mandarin', value: 'zh' },
];

interface IProps extends PropsWithoutRef<ResourceFormProps<User>> {
  userId?: number;
  userAccountState?: AccountState;
}

const UserFormBasicConfigSection = ({
  control,
  userId,
  setValue,
  userAccountState,
}: IProps) => {
  const { t } = useTranslation('common');
  const { data: currentUser } = useUsersCurrentRetrieveQuery();
  const isCurrentUser = userId === currentUser?.id;

  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        gap: 2,
      }}
    >
      {isCurrentUser && (
        <S3ImageField
          context="avatar"
          control={control}
          name="avatar"
          label={t('settings.profilePicture', 'Profile Picture')}
        />
      )}
      {FIELDS.map(({ title, caption, type, fieldName, required }) => (
        <Box key={fieldName} sx={{ display: 'grid' }}>
          <TextFieldRenderer
            name={fieldName}
            control={control}
            label={title}
            subtitle={caption}
            type={type}
            required={required}
            disableTextField={
              fieldName === 'email' && userAccountState === 'active'
            }
          />
        </Box>
      ))}
      <PasswordFieldRenderer
        control={control}
        name="password"
        label={t('settings.password', 'Password')}
        subtitle={t(
          'settings.passwordUserLoginWith',
          'The password the user will log in with',
        )}
        required={!userId}
        creatingNewUser={!userId}
        setValue={setValue}
        userAccountState={userAccountState}
      />
      <TextFieldRenderer
        name="user_channel_id"
        control={control}
        label={t('settings.userChannelId', 'User Channel ID')}
        subtitle={t(
          'settings.userTelegramIdToIntegrateWithAlerts',
          'User’s platform-telegram ID that will be used to interact with the alerts',
        )}
        type="text"
      />
      {isCurrentUser && (
        <>
          <SelectFieldRenderer
            label={t('settings.preferredLanguage', 'Preferred Language')}
            subtitle="Your chosen language for the app"
            name="preferred_language"
            control={control}
            items={LANGUAGE_CHOICES}
          />

          <SelectFieldRenderer
            label="Preferred Date Format"
            subtitle="Your preferred date format for the app"
            name="preferred_date_format"
            control={control}
            items={DATE_FORMATS}
          />
        </>
      )}
    </Box>
  );
};

export default UserFormBasicConfigSection;
