import React, { PropsWithoutRef } from 'react';

import { Select, MenuItem } from '@mui/material';
import type { User } from 'ts_shared';

import type { ResourceFormProps } from 'types';
import {
  useCompaniesRolesListQuery,
  useResellersRolesListQuery,
} from 'services/api/general';
import ControllerWithError from 'libs/react-hook-form/ControllerWithError';
import useIsInternalUsersPage from 'hooks/useIsInternalUsersPage';

const UserGroupDropdownForm = ({
  control,
}: PropsWithoutRef<ResourceFormProps<User>>) => {
  const isInternalUsersPage = useIsInternalUsersPage();

  const { data: roles } = isInternalUsersPage
    ? useResellersRolesListQuery()
    : useCompaniesRolesListQuery();

  return (
    <ControllerWithError
      control={control}
      name="group"
      render={({ field: { onChange, value } }) => (
        <Select
          label="User Group"
          id="user-groups-dropdown"
          onChange={(e) => onChange([e.target.value])}
          value={value || []}
          variant="standard"
          fullWidth
          size="small"
        >
          {roles?.map((role) => (
            <MenuItem key={role.id} value={role.id}>
              {role.name}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default UserGroupDropdownForm;
