import { LocaleObject } from 'yup';

export const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
};

export const DUMMYIMAGE = '/images/no-latest-frame.png';
export const DUMMY_LAYOUT_IMAGE = '/images/no-layout-image.png';
export const NO_PRIMARY_MUGSHOT = '/images/no_primary_mugshot.svg';

// Customizing default error messages for yup
export const customLocale: LocaleObject = {
  mixed: {
    required: 'This field is required',
    notType: ({ type }) => `Value must be a valid ${type}`,
  },
  number: {
    min: ({ min }) => `Value must be >= ${min}`,
    max: ({ max }) => `Value must be <= ${max}`,
    positive: 'Value must be positive number',
    integer: 'Value must only be integers',
  },
};

export const DATE_FORMATS = [
  { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
  { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
  { value: 'YYYY/MM/DD', label: 'YYYY/MM/DD' },
];
