import footfallTranslationsJa from './footfall-translations.json';
import workhourTranslationsJa from './workhour-translations.json';
import vehicleManagementTranslationsJa from './vehicle-management-translations.json';
import peopleRecognitionTranslationsJa from './people-recognition-translations.json';
import commonComponentsTranslationsJa from './common-components-translations.json';
import alertTabTranslationsJa from './alert-tab-translations.json';
import inspectionTabTranslationsJa from './inspection-tab-translations.json';
import captureLogsTranslationsJa from './capture-logs-translations.json';
import accessControlTranslationsJa from './access-control-translations.json';

const translationJA = {
  common: commonComponentsTranslationsJa,
  alertsTab: alertTabTranslationsJa,
  peopleRecognition: peopleRecognitionTranslationsJa,
  inspection: inspectionTabTranslationsJa,
  footfall: footfallTranslationsJa,
  workhour: workhourTranslationsJa,
  vehicleManagement: vehicleManagementTranslationsJa,
  captureLogs: captureLogsTranslationsJa,
  accessControl: accessControlTranslationsJa,
};

export default translationJA;
