import React from 'react';

import { Stack, type StackProps } from '@mui/material';
import {
  Controller,
  FieldPath,
  type ControllerProps,
  type FieldValues,
} from 'react-hook-form';

import ErrorMessage from './ErrorMessage';

interface ControllerWithErrorProps {
  stackProps?: StackProps;
  warnings?: string[];
}

const ControllerWithError = <
  T extends FieldValues,
  TName extends FieldPath<T>,
>({
  render,
  stackProps,
  warnings,
  ...otherProps
}: ControllerProps<T, TName> & ControllerWithErrorProps) => (
  <Controller
    render={(args) => (
      <Stack {...stackProps}>
        {render(args)}
        <ErrorMessage
          name={otherProps.name}
          errors={args.formState.errors}
          warnings={warnings}
        />
      </Stack>
    )}
    {...otherProps}
  />
);

export default ControllerWithError;
