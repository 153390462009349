import React from 'react';

import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
} from '@mui/material';

interface IProps extends ButtonProps {
  isLoading?: boolean;
  circularProgressProps?: CircularProgressProps;
}

const ButtonWithLoadingState = ({
  isLoading,
  circularProgressProps,
  children,
  onClick,
  ...btnProps
}: IProps) => (
  <Button onClick={(e) => !isLoading && onClick && onClick(e)} {...btnProps}>
    {isLoading ? (
      <CircularProgress size={20} {...circularProgressProps} />
    ) : (
      children
    )}
  </Button>
);

export default ButtonWithLoadingState;
