const AccessControlPermissions = {
  // Read
  ACCESS_CONTROL_VIEW_ACCESS_LOGS_PAGE: 'access_control_view_access_logs',
  ACCESS_CONTROL_VIEW_IAM: 'access_control_view_iam',
  PEOPLE_MANAGEMENT_CONFIGURE_READ: 'people_management_configure_read',

  // WRITE
  // CREATE and UPDATE share the same permission
  ACCESS_CONTROL_CREATE_FACE: 'access_control_create_face',
  PEOPLE_MANAGEMENT_CONFIGURE_WRITE: 'people_management_configure_write',

  // UPDATE
  ACCESS_CONTROL_EDIT_AUTHORISATION: 'access_control_edit_authorisation',
};

export default AccessControlPermissions;
