import React from 'react';

import { Box, Divider, List, Stack } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import FactoryIcon from '@mui/icons-material/Factory';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { useTranslation } from 'react-i18next';

import UserNotifications from 'components/User/UserNotifications';
import CommonPermissions from 'permissions/CommonPermissions';
import { useForceComponentUpdateOnLanguageChange } from 'i18n';
import AuthorisedRoutes from '../../../AuthorisedRoutes';
import sections from './sections';
import useGetCurrentSection from './hooks/useGetSection';
import SidebarMenuItem from './SidebarMenuItem';
import CurUserSettings from './CurUserSettings';

const useGetSection = () => {
  // This hook will return the routes of the current section/path while considering the subtabs
  const curSection = useGetCurrentSection('section');

  const currentSection = sections().find((s) =>
    s.subTabs
      ? s.subTabs.find((subTab) => subTab.path === curSection)
      : s.path === curSection,
  );

  const currentSubTabSection =
    currentSection?.subTabs?.find((subTab) => subTab.path === curSection) ||
    currentSection;

  return currentSubTabSection;
};

const Sidebar = () => {
  const section = useGetCurrentSection('section');
  const currentSection = useGetSection();
  const page = useGetCurrentSection('page');
  const { t } = useTranslation('common');
  useForceComponentUpdateOnLanguageChange();

  return (
    <List
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '1.5rem',
      }}
    >
      <Box>
        {currentSection?.routes?.map(({ url, name, icon, permission }) => (
          <AuthorisedRoutes
            tab_name={currentSection?.tab_access}
            Sidebar
            key={name}
          >
            <SidebarMenuItem
              key={name}
              url={url}
              name={name}
              icon={icon}
              permission={permission}
              page={page}
              section={section}
            />
          </AuthorisedRoutes>
        ))}
      </Box>
      <Stack alignItems="center" spacing={3}>
        <Stack>
          <Divider variant="fullWidth" flexItem />
          <SidebarMenuItem
            url="settings"
            name={t('common.settings', 'Settings')}
            icon={<SettingsIcon />}
            permission={CommonPermissions.VIEW_SETTINGS}
            section={section}
            page={page}
          />
          <SidebarMenuItem
            url="company-settings"
            name={t('common.companySettings', 'Company Settings')}
            icon={<FactoryIcon />}
            permission={CommonPermissions.VIEW_COMPANY_SETTINGS}
            section={section}
            page={page}
          />
          <SidebarMenuItem
            url="reseller-settings"
            name={t('common.resellerSettings', 'Reseller Settings')}
            icon={<HandshakeIcon />}
            permission={CommonPermissions.VIEW_RESELLER_SETTINGS}
            section={section}
            page={page}
          />
          <Divider variant="fullWidth" flexItem />
        </Stack>
        <Stack alignItems="center" spacing={1}>
          <UserNotifications />
          <CurUserSettings />
        </Stack>
      </Stack>
    </List>
  );
};

export default Sidebar;
