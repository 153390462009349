import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { useForceUpdate } from '@mantine/hooks';

import translationEN from './locales/en';
import translationZH from './locales/zh';
import translationJA from './locales/ja';

const resources = {
  en: translationEN,
  zh: translationZH,
  ja: translationJA,
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

/**
 * Some components might render before the language is correctly set.
 * For example, if the user's preferred language is not English, some
 * components might finish rendering before the user's data is loaded
 * to set their preferred language correctly. This hook helps these
 * components render the correct language for the user.
 */
export const useForceComponentUpdateOnLanguageChange = () => {
  const forceUpdate = useForceUpdate();
  i18n.on('languageChanged', forceUpdate);
};

export default i18n;
