import { useUsersCurrentRetrieveQuery } from 'services/api/general';

interface UseHasPermissionProps {
  permission?: string | string[];
  superuser?: boolean;
}

const useHasPermission = () => {
  const { data } = useUsersCurrentRetrieveQuery();

  return ({ permission, superuser }: UseHasPermissionProps) => {
    if (data?.is_superuser) return true;

    if (superuser && !data?.is_superuser) return false;

    const permissionArray = Array.isArray(permission)
      ? permission
      : [permission];
    const curUserPerms = data?.perms;

    // if permission is undefined or falsy, we assume the user has permission
    const userHasPermission =
      permissionArray.filter(Boolean).length === 0
        ? true
        : curUserPerms?.some((perm) => permissionArray.includes(perm));

    return userHasPermission;
  };
};

export default useHasPermission;
