import React, { cloneElement, ReactElement } from 'react';

import useHasPermission from './useHasPermission';
import FieldSet from '../Forms/FieldSet';
import { PermissionsProps, PermissionType } from './types';

interface WithPermissionsProps extends PermissionsProps {
  children: React.ReactNode;
  // If permission failed, the children will be cloned and pass this props
  failPermissionProps?: any extends object ? {} : never;
}

const WithPermissions = ({
  children,
  viewOnlyOnFailed,
  failPermissionProps,
  ...props
}: WithPermissionsProps) => {
  const hasPermission = useHasPermission()(props);

  if (hasPermission) {
    return children as ReactElement;
  }

  if (failPermissionProps && React.isValidElement(children)) {
    return cloneElement(children as ReactElement, failPermissionProps);
  }

  if (viewOnlyOnFailed) {
    return <FieldSet viewOnly>{children}</FieldSet>;
  }

  return null;
};

export default WithPermissions;

interface WithReadWritePermsProps {
  read: PermissionType;
  write: PermissionType;
  superuser?: boolean;
  children: React.ReactNode;
  viewOnlyOnFailed?: boolean;
}

export const WithReadWritePerms = ({
  read,
  write,
  superuser,
  children,
  viewOnlyOnFailed = true,
}: WithReadWritePermsProps) => {
  const hasWritePerm = useHasPermission()({ permission: write, superuser });
  const hasReadPerm = useHasPermission()({ permission: read, superuser });

  if (hasWritePerm) {
    return children as ReactElement;
  }

  if (hasReadPerm) {
    return <FieldSet viewOnly={viewOnlyOnFailed}>{children}</FieldSet>;
  }

  return null;
};
