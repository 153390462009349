import React from 'react';

import { Stack, Typography, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IProps {
  confirmationText?: string;
  description: string | JSX.Element;
  setConfirmationInputVal: React.Dispatch<React.SetStateAction<string>>;
}

const ConfirmationBody = ({
  confirmationText,
  description,
  setConfirmationInputVal,
}: IProps) => {
  const { t } = useTranslation('common');
  return (
    <Stack spacing={2}>
      {typeof description === 'string' ? (
        <Typography variant="body1">{description}</Typography>
      ) : (
        description
      )}

      {confirmationText && (
        <TextField
          size="small"
          label={`${t('common.type', 'Type')} "${confirmationText}" ${t(
            'common.toConfirm',
            'to confirm',
          )}`}
          onChange={(e) => setConfirmationInputVal(e.target.value)}
        />
      )}
    </Stack>
  );
};

export default ConfirmationBody;
