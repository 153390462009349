/* eslint-disable no-nested-ternary */
import React, { PropsWithoutRef, useMemo } from 'react';

import { Select, MenuItem } from '@mui/material';
import type { User } from 'ts_shared';

import type { ResourceFormProps } from 'types';
import { useSitesListQuery } from 'services/api/general';
import ControllerWithError from 'libs/react-hook-form/ControllerWithError';

const UserSitesSelectDropdown = ({
  control,
  watch,
}: PropsWithoutRef<ResourceFormProps<User>>) => {
  const { data: sites } = useSitesListQuery({});

  const selectedCompany = watch ? watch('company') : null;

  const sitesFromSelectedCompany = useMemo(() => {
    const filteredSites = sites?.filter(
      (site) => site.company === selectedCompany,
    );

    return filteredSites;
  }, [selectedCompany, sites]);

  const errorMessage = () => {
    if (!selectedCompany) {
      return 'Please select a company first';
    }

    return 'Selected company has no sites';
  };

  return (
    <ControllerWithError
      stackProps={{ sx: { minWidth: 0 } }}
      control={control}
      name="sites"
      render={({ field: { onChange, value } }) => (
        <Select
          id="user-sites-dropdown"
          onChange={(e) => onChange(e.target.value)}
          value={value || []}
          variant="standard"
          multiple
          fullWidth
          size="small"
          sx={{ minWidth: '0' }}
        >
          {sitesFromSelectedCompany && sitesFromSelectedCompany.length > 0 ? (
            sitesFromSelectedCompany.map((site) => (
              <MenuItem key={site.id} value={site.id}>
                {site.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled value="">
              <em>{errorMessage()}</em>
            </MenuItem>
          )}
        </Select>
      )}
    />
  );
};

export default UserSitesSelectDropdown;
