import footfallTranslationsEn from './footfall-translations.json';
import workhourTranslationsEn from './workhour-translations.json';
import vehicleManagementTranslationsEn from './vehicle-management-translations.json';
import peopleRecognitionTranslationsEn from './people-recognition-translations.json';
import commonComponentsTranslationsEn from './common-components-translations.json';
import alertTabTranslationsEn from './alert-tab-translations.json';
import inspectionTabTranslationsEn from './inspection-tab-translations.json';
import captureLogsTranslationsEn from './capture-logs-translations.json';
import accessCOntrolTranslationsEn from './access-control-translations.json';

const translationEN = {
  common: commonComponentsTranslationsEn,
  alertsTab: alertTabTranslationsEn,
  peopleRecognition: peopleRecognitionTranslationsEn,
  inspection: inspectionTabTranslationsEn,
  footfall: footfallTranslationsEn,
  workhour: workhourTranslationsEn,
  vehicleManagement: vehicleManagementTranslationsEn,
  captureLogs: captureLogsTranslationsEn,
  accessControl: accessCOntrolTranslationsEn,
};

export default translationEN;
