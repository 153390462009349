import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PrimaryLabelFilter = {
  siteIds: [string] | [];
  labelIds: string[];
};

type CaptureLogsFilter = {
  selectedLabels: PrimaryLabelFilter;
};

const initialState: CaptureLogsFilter = {
  selectedLabels: {
    siteIds: [],
    labelIds: [],
  },
};

const captureLogsDashboardFilterSlice = createSlice({
  name: 'captureLogsDashboardFilters',
  initialState,
  reducers: {
    updateLabels(state, action: PayloadAction<PrimaryLabelFilter>) {
      state.selectedLabels = action.payload;
    },
  },
});

export const { updateLabels } = captureLogsDashboardFilterSlice.actions;

export default captureLogsDashboardFilterSlice.reducer;
