import { DateRange } from '@mui/x-date-pickers-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subWeeks } from 'date-fns';

import { AuditTypeEnum } from '../auditTrailApi';

export type AuditTrailTab = 'alerts' | 'vehicle_management';

type AuditTrailFilterType = {
  tab: AuditTrailTab;
  users: number[];
  cameras: string[];
  changeAction: AuditTypeEnum;
  timeRange: DateRange<Date>;
};

const initialState: AuditTrailFilterType = {
  tab: 'alerts',
  users: [],
  cameras: [],
  changeAction: 'change',
  timeRange: [subWeeks(new Date(), 1), new Date()],
};

const auditTrailDashboardFilterSlice = createSlice({
  name: 'auditTrailDashboardFilters',
  initialState,
  reducers: {
    updateTab(state, action: PayloadAction<AuditTrailTab>) {
      state.tab = action.payload;
    },
    updateUsers(state, action: PayloadAction<number[]>) {
      state.users = action.payload;
    },
    updateCameras(state, action: PayloadAction<string[]>) {
      state.cameras = action.payload;
    },
    updateChangeAction(state, action: PayloadAction<AuditTypeEnum>) {
      state.changeAction = action.payload;
    },
    updateTimeRange(state, action: PayloadAction<DateRange<Date>>) {
      state.timeRange = action.payload;
    },
  },
});

export const {
  updateTab,
  updateUsers,
  updateCameras,
  updateChangeAction,
  updateTimeRange,
} = auditTrailDashboardFilterSlice.actions;

export default auditTrailDashboardFilterSlice.reducer;
