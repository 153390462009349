const CommonPermissions = {
  // VIEW
  VIEW_CAMERA_STREAMS: 'CRV01',
  LIST_CAMERAS: 'CRL01',
  LIST_USERS: 'CRU01',
  LIST_SITES: 'CRS01',
  LIST_PROCESSING_NODES: 'CRD01',
  RETRIEVE_PROCESSING_NODES: 'CRD02',
  RETRIEVE_COMPANIES: 'CRC01',
  LIST_COMPANIES: 'CRL02',
  LIST_ROLES: 'CRL03',
  RETRIEVE_RESELLERS: 'CRR02',
  LIST_RESELLERS: 'CRL04',
  RETRIEVE_USERS: 'CRU02',
  LIST_GROUPS: 'CRSG1',
  VIEW_SETTINGS: 'common_settings_page_read',
  VIEW_COMPANY_SETTINGS: 'common_company_settings_page_read',
  VIEW_RESELLER_SETTINGS: 'common_reseller_settings_page_read',

  //   WRITE
  CREATE_COMPANIES: 'CWC01',
  CREATE_USERS: 'CWU01',
  CREATE_RESELLERS: 'CWR01',
  CREATE_PROCESSING_NODES: 'CWD01',
  WRITE_CONTROL_PANEL_PAGE: 'operations_page_write',

  // UPDATE
  UPDATE_COMPANIES: 'CUC01',
  UPDATE_USERS: 'CUU01',
  UPDATE_RESELLERS: 'CUR01',
  UPDATE_PROCESSING_NODES: 'CUD01',
  UPDATE_OWN_USER_DETAILS: 'CUU02',

  //   DELETE
  DELETE_CAMERAS: 'CDA01',
  DELETE_GROUPS: 'CDG01',
  DELETE_SITES: 'CDS01',
  DELETE_COMPANIES: 'CDC01',
  DELETE_USERS: 'CDU01',
  DELETE_RESELLERS: 'CDR01',
  DELETE_PROCESSING_NODES: 'CDD01',

  // Action

  KILL_APN: 'CAT02',
};

export default CommonPermissions;
