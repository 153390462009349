import React, { useEffect, useState } from 'react';

import {
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { IconButton, InputAdornment, Box, Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AccountState } from 'ts_shared';

import generateNewPassword from 'utils/generateNewPassword';
import PasswordValidator from '../PasswordValidator';
import { TextFieldRenderer, TextFieldRendererProps } from '../FieldRenderers';

interface IProps<T extends FieldValues>
  extends Omit<TextFieldRendererProps<T>, 'type'> {
  creatingNewUser?: boolean;
  setValue?: UseFormSetValue<T>;
  userAccountState?: AccountState;
}

const PasswordFieldRenderer = <T extends FieldValues>({
  textFieldProps,
  creatingNewUser,
  setValue,
  userAccountState,
  ...otherProps
}: IProps<T>) => {
  const { control, name } = otherProps;
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const password = useWatch({ control, name });

  const handleInputClick = () => {
    setIsFocused(true);
  };

  const handleIconClick = () => {
    setShowPassword(!showPassword);
    setIsFocused(true);
  };

  const newOrInvitedUser = creatingNewUser || userAccountState === 'invited';

  const setRandomPassword = () => {
    if (setValue) {
      setValue(name, generateNewPassword() as PathValue<T, Path<T>>, {
        shouldDirty: true,
      });
    }
  };

  useEffect(() => {
    if (newOrInvitedUser) {
      // NOTE: Workaround for filling password field when editing an 'invited' user
      // See: https://github.com/react-hook-form/react-hook-form/issues/2578#issue-677653277
      setTimeout(() => setRandomPassword());
    }

    // dependency required for editing invited users, as it seems like on the first render,
    // userAccountState is undefined, so we run the effect again when it changes to 'invited'
  }, [userAccountState]);

  return (
    <Box>
      <TextFieldRenderer
        textFieldProps={{
          InputProps: {
            onClick: handleInputClick,
            onBlur: () => setIsFocused(false),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleIconClick}
                  onBlur={() => setIsFocused(!isFocused)}
                >
                  {showPassword || newOrInvitedUser ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          },
          ...textFieldProps,
        }}
        type={showPassword || newOrInvitedUser ? 'text' : 'password'}
        disableTextField={newOrInvitedUser}
        fieldAction={
          newOrInvitedUser ? (
            <Button variant="outlined" onClick={setRandomPassword}>
              REGENERATE
            </Button>
          ) : undefined
        }
        {...otherProps}
      />
      <PasswordValidator isVisible={isFocused} password={password} />
    </Box>
  );
};

export default PasswordFieldRenderer;
