/* eslint import/no-cycle: [0] */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { type OperationsFilterType } from 'ts_shared';

import { RootState } from 'store';

type InitialStateType = {
  selectedOperationsFilterOpts: OperationsFilterType[];
};

const initialState: InitialStateType = {
  selectedOperationsFilterOpts: [],
};

const operationsFilter = createSlice({
  name: 'operationsFilter',
  initialState,
  reducers: {
    handleSelectOperationsFilterOpt(
      state,
      action: PayloadAction<OperationsFilterType[]>,
    ) {
      state.selectedOperationsFilterOpts = action.payload;
    },
  },
});

export const useGetSelectedOperationsFilterOpts = () =>
  useSelector(
    (state: RootState) => state.operationsFilter.selectedOperationsFilterOpts,
  );

export const { handleSelectOperationsFilterOpt } = operationsFilter.actions;
export default operationsFilter.reducer;
