import { useEffect, useState } from 'react';

const useHasUserInteractedWithDom = () => {
  const [hasUserInteractedWithDom, setHasUserInteractedWithDom] =
    useState(false);

  const updateHasInteracted = () => {
    setHasUserInteractedWithDom(true);
  };

  useEffect(() => {
    if (hasUserInteractedWithDom) return undefined;

    window.addEventListener('click', updateHasInteracted);
    window.addEventListener('keydown', updateHasInteracted);

    return () => {
      window.removeEventListener('click', updateHasInteracted);
      window.removeEventListener('keydown', updateHasInteracted);
    };
  }, []);

  return hasUserInteractedWithDom;
};

export default useHasUserInteractedWithDom;
