import emptySplitApi from 'services/api/ailyssaBaseApi';
import {
  NotificationsMarkAsReadByRecordIdCreateApiArg,
  NotificationsMarkAsReadByRecordIdCreateApiResponse,
} from './types';

const notificationsApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    notificationsMarkAsReadByRecordIdCreate: build.mutation<
      NotificationsMarkAsReadByRecordIdCreateApiResponse,
      NotificationsMarkAsReadByRecordIdCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/notifications/mark_as_read_by_record_id/`,
        method: 'POST',
        body: queryArg.markNotificationsAsReadRequest,
      }),
      invalidatesTags: ['UserNotifications', 'UserUnreadNotificationsCount'],
    }),
  }),
});

export const { useNotificationsMarkAsReadByRecordIdCreateMutation } =
  notificationsApi;

export * from './types';
