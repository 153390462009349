import emptySplitApi, { tags } from 'services/api/ailyssaBaseApi';
import {
  AuthOtpGenerateCreateApiResponse,
  AuthOtpGenerateCreateApiArg,
  AuthTokenGenerateCreateApiResponse,
  AuthTokenGenerateCreateApiArg,
  AuthPasswordResetCreateApiResponse,
  AuthPasswordResetCreateApiArg,
  AuthPasswordResetConfirmCreateApiResponse,
  AuthPasswordResetConfirmCreateApiArg,
} from './types';

const authApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    authOtpGenerateCreate: build.mutation<
      AuthOtpGenerateCreateApiResponse,
      AuthOtpGenerateCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/auth/otp/generate/',
        method: 'POST',
        body: queryArg.requestOtpPayload,
      }),
    }),
    authLogin: build.mutation<
      AuthTokenGenerateCreateApiResponse,
      AuthTokenGenerateCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/auth/login/',
        method: 'POST',
        body: queryArg.tokenRequestPayload,
      }),
      invalidatesTags: tags,
    }),

    authLogout: build.mutation<void, void>({
      query: () => ({
        url: '/auth/logout/',
        method: 'POST',
      }),
    }),

    otpProvisionNew: build.mutation<string, void>({
      query: () => ({
        url: '/auth/otp/provision/',
        method: 'POST',
      }),
    }),
    authPasswordResetCreate: build.mutation<
      AuthPasswordResetCreateApiResponse,
      AuthPasswordResetCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/auth/password-reset/',
        method: 'POST',
        body: queryArg.email,
      }),
    }),
    authPasswordResetConfirmCreate: build.mutation<
      AuthPasswordResetConfirmCreateApiResponse,
      AuthPasswordResetConfirmCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/auth/password-reset/confirm/',
        method: 'POST',
        body: queryArg.passwordToken,
      }),
    }),
  }),
});

export const {
  useAuthOtpGenerateCreateMutation,
  useOtpProvisionNewMutation,
  useAuthPasswordResetCreateMutation,
  useAuthPasswordResetConfirmCreateMutation,
  useAuthLoginMutation,
  useAuthLogoutMutation,
} = authApi;

export * from './types';
