import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type PrimaryLabelFilter = {
  siteIds: [string] | [];
  labelIds: string[];
};
export type Authorisations = 'authorised' | 'unauthorised' | 'unknown';

type AccessControlFilter = {
  selectedLabels: PrimaryLabelFilter;
  selectedAuthorisations: Authorisations[];
};

const initialState: AccessControlFilter = {
  selectedLabels: {
    siteIds: [],
    labelIds: [],
  },
  selectedAuthorisations: [],
};

const accessControlDashboardFilterSlice = createSlice({
  name: 'accessControlDashboardFilters',
  initialState,
  reducers: {
    updateLabels(state, action: PayloadAction<PrimaryLabelFilter>) {
      state.selectedLabels = action.payload;
    },
    updateAuthorisations(state, action: PayloadAction<Authorisations[]>) {
      state.selectedAuthorisations = action.payload;
    },
  },
});

export const { updateLabels, updateAuthorisations } =
  accessControlDashboardFilterSlice.actions;

export default accessControlDashboardFilterSlice.reducer;
