import React from 'react';

import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router';
import LogoutIcon from '@mui/icons-material/Logout';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAuthLogoutMutation } from 'services/api/general';

const LogoutButton = () => {
  const [logout] = useAuthLogoutMutation();

  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleOnClick = () => {
    logout()
      .unwrap()
      .then(() => {
        enqueueSnackbar(t('common.logoutSuccess', 'Logged out successfully.'), {
          variant: 'success',
        });
        navigate('/Login');
      });
  };

  return (
    <ListItemButton onClick={handleOnClick}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText
        primary={t('common.logout', 'Logout')}
        sx={{ fontSize: '1.2rem' }}
      />
    </ListItemButton>
  );
};

export default LogoutButton;
