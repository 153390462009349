import footfallTranslationsZh from './footfall-translations.json';
import workhourTranslationsZh from './workhour-translations.json';
import vehicleManagementTranslationsZh from './vehicle-management-translations.json';
import peopleRecognitionTranslationsZh from './people-recognition-translations.json';
import commonComponentsTranslationsZh from './common-components-translations.json';
import alertTabTranslationsZh from './alert-tab-translations.json';
import inspectionTabTranslationsZh from './inspection-tab-translations.json';
import captureLogsTranslationsZh from './capture-logs-translations.json';
import accessControlTranslationsZh from './access-control-translations.json';

const translationZH = {
  common: commonComponentsTranslationsZh,
  alertsTab: alertTabTranslationsZh,
  peopleRecognition: peopleRecognitionTranslationsZh,
  inspection: inspectionTabTranslationsZh,
  footfall: footfallTranslationsZh,
  workhour: workhourTranslationsZh,
  vehicleManagement: vehicleManagementTranslationsZh,
  captureLogs: captureLogsTranslationsZh,
  accessControl: accessControlTranslationsZh,
};

export default translationZH;
