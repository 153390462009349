import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useDisclosure } from '@mantine/hooks';
import i18n from 'i18n';

import { ConfirmationDialogProps } from './types';
import ConfirmationBody from './ConfirmationBody';
import ConfirmationFooter from './ConfirmationFooter';
import DialogHeader from './DialogHeader';

const ConfirmationDialog = ({
  description,
  triggerButton,
  title = i18n.t('common.areYouSure', 'Are you sure?', { ns: 'common' }),
  confirmationText,
  triggerOnMount = false,
  dialogProps,
  dialogContentProps,
  dialogTitleProps,
  afterClose,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  cancelProps,
}: ConfirmationDialogProps) => {
  const [isOpen, { close, open }] = useDisclosure(triggerOnMount);
  const [confirmationInputVal, setConfirmationInputVal] = useState('');

  const handleCancel = () => {
    if (onCancel) onCancel();
    close();
  };

  const handleConfirm = () => {
    onConfirm();
    close();
  };

  const trigger =
    triggerButton &&
    React.cloneElement(triggerButton, {
      onClick: open,
    });

  useEffect(() => {
    if (!isOpen && afterClose) afterClose();
  }, [afterClose, isOpen]);

  return (
    <>
      {trigger}
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isOpen}
        onClose={handleCancel}
        {...dialogProps}
        sx={{ zIndex: 1500 }}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle {...dialogTitleProps}>
          <DialogHeader title={title} handleClose={handleCancel} />
        </DialogTitle>
        <DialogContent {...dialogContentProps}>
          <Stack spacing={2}>
            <ConfirmationBody
              description={description}
              confirmationText={confirmationText}
              setConfirmationInputVal={setConfirmationInputVal}
            />
            <ConfirmationFooter
              confirmationInputVal={confirmationInputVal}
              confirmationText={confirmationText}
              handleCancel={handleCancel}
              onConfirm={handleConfirm}
              confirmText={confirmText}
              cancelText={cancelText}
              cancelProps={cancelProps}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
