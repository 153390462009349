import useGetCurrentSection from 'components/common/header/hooks/useGetSection';

const useIsInternalUsersPage = () => {
  const currentPage = useGetCurrentSection('page');
  const section = useGetCurrentSection('section');

  return (
    currentPage === 'reseller-settings' ||
    currentPage === 'operations' ||
    (section === 'control-panel' && currentPage === 'dashboard')
  );
};

export default useIsInternalUsersPage;
