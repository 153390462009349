import React from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@mui/material';
import { type NotificationReadAllTabName } from 'ts_shared';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

const FILTER_BY_TABS_OPTIONS: {
  value: NotificationReadAllTabName;
  label: string;
}[] = [
  {
    value: 'all',
    label: i18n.t('user.all', 'All', { ns: 'common' }),
  },
  {
    value: 'alert',
    label: i18n.t('user.alerts', 'Alerts', { ns: 'common' }),
  },
  {
    value: 'people_recognition',
    label: i18n.t('user.peopleRecognition', 'People Recognition', {
      ns: 'common',
    }),
  },
];

interface IProps {
  selectedFilterTab: NotificationReadAllTabName;
  setSelectedFilterTab: React.Dispatch<
    React.SetStateAction<NotificationReadAllTabName>
  >;
}

const FilterByTabs = ({ selectedFilterTab, setSelectedFilterTab }: IProps) => {
  const { t } = useTranslation('common');
  const handleChange = (e: SelectChangeEvent) => {
    setSelectedFilterTab(e.target.value as NotificationReadAllTabName);
  };

  return (
    <FormControl sx={{ flex: '1 1 0px' }} size="small">
      <InputLabel id="filter-by-tab-label">
        {t('user.platform', 'Platform')}
      </InputLabel>
      <Select
        labelId="filter-by-tab-label"
        id="filter-by-tab"
        value={selectedFilterTab || ''}
        onChange={handleChange}
        label="Notifications"
      >
        {FILTER_BY_TABS_OPTIONS.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterByTabs;
