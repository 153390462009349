import React from 'react';

import { CircularProgress, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface WithLoadingStateProps {
  hasData?: boolean;
  error?: unknown;
  isLoading: boolean;
  children: React.ReactNode;
  noDataError?: string;
  spinner?: JSX.Element;
}

const WithLoadingState = ({
  hasData,
  error,
  isLoading,
  children,
  noDataError,
  spinner,
}: WithLoadingStateProps) => {
  const { t } = useTranslation('common');
  if (isLoading)
    return (
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          p: 2,
        }}
      >
        {spinner || <CircularProgress />}
      </Stack>
    );

  if (error)
    return (
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {t('somethingWentWrong', 'Something went wrong')}
      </Stack>
    );

  if (!hasData && !isLoading)
    return (
      <Stack
        sx={{
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          p: 1,
        }}
      >
        {noDataError || t('noItemsToDisplay', 'No items to display')}
      </Stack>
    );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default WithLoadingState;
