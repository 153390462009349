import React, {
  DependencyList,
  createContext,
  useContext,
  useEffect,
} from 'react';

import { type ThrottleSettings, throttle } from 'lodash';
import { io } from 'socket.io-client';

export const socket = io(import.meta.env.VITE_SOCKET_CONNECTION_URL, {
  withCredentials: true,
});

const SocketContext = createContext(socket);

const SocketContextProvider = ({ children }: { children: React.ReactNode }) => (
  <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
);

export const useSocketContext = () => useContext(SocketContext);

export type WebsocketMessageEnum =
  | 'new_alert'
  | 'new_notification'
  | 'configured_area_update'
  | 'new_person_record'
  | 'new_vehicle_record'
  | 'new_inspection_record'
  | 'new_vehicle_transit_record'
  | 'new_capture_log_record'
  | undefined;

interface UseThrottledRefetchListenerProps {
  message: WebsocketMessageEnum;
  refetch: (eventData: any) => void;
  throttledMS?: number;
  throttleSettings?: ThrottleSettings;
  deps?: DependencyList;
  stopListening?: boolean;
}

export const useThrottledRefetchListener = ({
  message,
  refetch,
  throttledMS = 5000,
  throttleSettings = {
    trailing: true,
  },
  deps,
  stopListening,
}: UseThrottledRefetchListenerProps) => {
  const socketCtx = useSocketContext();

  useEffect(() => {
    const throttledRefetch = throttle(refetch, throttledMS, throttleSettings);

    const handleStopListening = () => {
      socketCtx.off(message, throttledRefetch);
    };

    if (message && !stopListening) {
      socketCtx.on(message, throttledRefetch);
    }

    if (stopListening) handleStopListening();

    return () => {
      handleStopListening();
    };
  }, [message, socketCtx, ...(deps || []), stopListening]);
};

export default SocketContextProvider;
